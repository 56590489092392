footer {
    display: flex;
    flex-direction: column;
    background-color: #E8E8E8;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Open Sans",sans-serif;
    overflow: hidden;
}
footer a {
    color: black;
    transition: color 0.3s;
    font-size: 17px;
}


footer a:hover {
    transition: color 0.3s;
    color: #54c2f0;
    text-decoration: none;
}

