
#nav-logo {
    user-select: none;
    -webkit-user-drag: none;
    margin-left: 20px;
}
.nav-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

}
.nav-content > ul  {
    display: flex;
    flex-direction: row;
    list-style: none;

}
.navlink, .external-link {

    
    font-family: "Open sans",sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    color:black;
    margin-left: 20px;
    margin-right: 20px;
    

    margin-top: 10px;
}
.external-link {
    margin-top: 20px;
}
.navlink:hover {
    text-decoration: none;
    color:black;
}

.link-underline {
    position: relative;
    display:inline-block;
}
.link-underline::before{
        content: "";
        position: absolute;
        width: 0;
        height: 1.5px;
        bottom: 0;
        left: 0;
        background-color: black;
        visibility: "hidden";
        transition: all 0.3s ease-in-out;
      
}
.link-underline:hover::before{
        visibility: visible;
        width: 100%;
}
.current::before{
    visibility: visible;
    width: 100%;
}

a {
    text-decoration: none;
    color: black;
}

#language-selector-btn {

    background:transparent;
    outline: none;
    border: none;
    color: black
}
#language-selector-btn:focus,#language-selector-btn:active {
    outline: none !important;
    box-shadow: none;
 }
.language-selector-item:focus,.language-selector-item:active {
    background-color: red;

 }
#language-selector-btn:focus {
    outline: none;
}
#language-selector-btn:hover {
    cursor: pointer;
}

.dropdown {
    list-style: none; 
    background: transparent; 
    padding: 10px; 
    display: inline-block;
}
.dropdown-menu .dropdown-item {display: inline-block; width: 100%; padding: 10px 5px;}
.dropdown .dropdown-menu a:hover
{
  color: #fff;
  background-color: #54c2f0;
  border-color: #fff;
}
.my-password-manager-btn {
    outline: none;
    border: 2px #54c2f0 solid;
    border-radius: 5px;
    background-color: white;
    color:#54c2f0;
    font-family: "Open sans",sans-serif;
    font-size: 20px;
    font-weight: 600;
    padding: 10px ;
    transition: background 0.5s;
    width: 250px;
    text-decoration: none;
}
.my-password-manager-btn:hover {
    background-color: rgba(223,223,223,0.5);
    transition: background 0.5s;
    color:#54c2f0;
    text-decoration: none;
    cursor: pointer;

}
@media all and (max-width: 1030px) {
    .navlink, .external-link, .my-password-manager-btn {
        display: none;
    }
    #language-selector-btn {
        position: absolute;
        top: -12.5px;
        right: 0px;
    }
}


