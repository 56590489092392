.home {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Open Sans", sans-serif;
}
.home-header {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home-header > h1 {
  margin-bottom: 30px;
  font-weight: 600;
}
 .home-header > .slogan {
  margin-top: 15px;
}
.home-header > .icon {
  user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
}
.get-started-button {

  margin-top: 15px;
  outline: none;
  border: 2px #54c2f0 solid;
  border-radius: 5px;
  background-color: white;
  color:#54c2f0;
  font-family: "Open sans",sans-serif;
  font-size: 20px;
  font-weight: 600;
  padding: 10px ;
  transition: background 0.5s;
  width: 200px;
  text-decoration: none;

}
.get-started-button:hover {
  background-color: rgba(223,223,223,0.5);
  transition: background 0.5s;
  color:#54c2f0;
  text-decoration: none;
  cursor: pointer;
}
.home > .features-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(198,237,240,0.35);
  margin-top: 50px;
  width: 100%;
}
.features-title {
  font-weight: 600;
  font-size: 25px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.features {
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.feature {
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 650px;
  height: 230px;
  background-color: white;
  border-radius: 20px;
  -webkit-box-shadow: 3px 3px 10px 4px rgba(0,0,0,0.3); 
  box-shadow: 3px 3px 10px 4px rgba(0,0,0,0.3);
  transition: all 0.5s ease;


}
.feature:hover {
  transition: all 0.5s ease;
   transform:scale(1.05);
}

.feature-info {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.feature-title {
  margin-top: 25px;
  font-size: 25px;
  font-weight: 600;

}

.feature-icon {
  margin-left: 15px;
  margin-right: 25px;
  font-size: 150px;

}
@media all and (max-width:680px) {
  .feature {
    width: 450px;
  }
  .feature-icon {
    font-size: 100px;

  }
  .feature-title {
    font-size: 18px;
  }
  .feature-desc {
    font-size: 14px;
  }
}
@media all and (max-width:460px) {
  .feature {
    width: 350px;
  }
  .feature-icon {
    font-size: 65px;

  }
  .feature-title {
    font-size: 16px;
  }
  .feature-desc {
    font-size: 13px;
  }

  .home-header > h1 {
    font-size: 30px;
  }
   .home-header > .slogan {
    font-size: 20px;

   }
}

@media all and (max-width:365px) {
  .feature {
    flex-direction: column;
    width: 95%;
  }
  .feature-icon {
    font-size: 55px;
    margin-right: 18px;

  }
  .feature-title {
    font-size: 16px;
  }
  .feature-desc {
    font-size: 11px;
  }

  .home-header > h1 {
    font-size: 20px;
  }
   .home-header > .slogan {
    font-size: 15px;

   }
} 