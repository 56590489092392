.downloads {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Open Sans", sans-serif;
    background-color: rgba(198,237,240,0.35);

  }
.downloads > h1, .downloads > h2 {
    margin-top: 15px;
    margin-bottom: 30px;
    font-weight: 600;

  }
.platforms {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
  margin-bottom: 30px;
}
.platform {
  font-size: 20px;
  font-weight: 600;
  margin-top: 50px;
  margin-left: 50px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 200px;
  border-radius: 20px;
  height: 250px;
  background-color: white;
  -webkit-box-shadow: 3px 3px 10px 4px rgba(0,0,0,0.3); 
  box-shadow: 3px 3px 10px 4px rgba(0,0,0,0.3);
  transition: transform 0.5s ease;

}
.platform:hover {
    transition: transform 0.5s ease;
     transform:scale(1.05);
}
.platform > .download-btn {
  color: black;
  margin-top: 10px;
  padding: 14px;
  user-select: none;
  border: 2px #54c2f0 solid;
  border-radius: 5px;
  background-color: white;
  color:#54c2f0;
  font-family: "Open sans",sans-serif;
  font-size: 20px;
  font-weight: 600;
  padding: 10px ;
  transition: background 0.5s;
  transition: color 0.5s;
  text-decoration: none;

}
.downloads a {
  color: black;
  transition: color 0.3s;
  font-size: 16px;
}
.downloads a:hover {
  transition: color 0.3s;
  color: #54c2f0;
  text-decoration: underline;
}

.platform > .download-btn:hover {
  text-decoration: none;
  cursor: pointer;
  background-color: rgba(223,223,223,0.5);
  transition: background 0.5s;
  transition: color 0.5s;
}
.all-releases {
  margin-bottom: 20px;
}
.platform-img {
  user-select: none;
  -webkit-user-drag: none;
}
@media all and (max-width:812px) {
  .platforms {
    flex-direction: column;
  }
  .platform {
    flex-direction: column;
    margin-left: 0px;
  }
}


  @media all and (max-width:460px) {

    .downloads > h1 {
      font-size: 30px;
    }
    .downloads > h2 {
      font-size: 25px;
  
     }
  }
  
  @media all and (max-width:365px) {

    .downloads > h1 {
      font-size: 20px;
    }
    .downloads > h2 {
        font-size: 18px;
    
       }
    
  } 