.responsive-btn {
    width: 2rem;
    height: 2rem;
    top: 35px;
    right: 65px;
    z-index: 20;
    display: none;
    
}

@media (max-width: 1030px) {
    .responsive-btn {
        display: flex;
        justify-content: space-around;
        flex-flow: column nowrap;
    }
  }
  .responsive-btn > div{
    width: 2rem;
    height: 0.25rem;
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
  }